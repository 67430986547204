<template>
  <div class="charts mb-3" style="height: 800px">
    <div v-if="data && data.activityTags && data.activityTags.length > 0">
      <ag-charts-vue :options="options"></ag-charts-vue>
      <div class="buttons-group">
        <button @click="toggleAll(false)" class="button-outline-blue switch">
          Disable All
        </button>
        <button @click="toggleAll(true)" class="button-outline-blue switch">
          Enable All
        </button>
      </div>
      <div class="custom-legend">
        <div
          class="legend-item"
          v-for="(activityTag, index) in data.activityTags"
          :key="index"
          @click="toggleActive(index)"
          :class="{ active: activityTag.active, inactive: !activityTag.active }"
        >
          <input
            type="checkbox"
            :checked="activityTag.active"
            @change="toggleActive(index)"
            class="hidden-checkbox"
          />
          <div class="color" :style="{ backgroundColor: activityTag.color }"></div>
          <div class="label">{{ activityTag.label }} ({{ priceFormatter(activityTag.total) }})</div>
        </div>
      </div>
    </div>
    <b-skeleton height="100%" v-else></b-skeleton>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  name: 'projects-charts-efc',
  data() {
    return {
      options: null,
      areSeriesVisible: true,
      mode: null
    }
  },
  props: ['data'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.setOptions()
    }
  },
  created() {
    this.mode = this.$store.getters.getExpChartsMode
    this.setOptions()
  },
  methods: {
    priceFormatter(total) {
      // Округляем число до ближайшего кратного 10
      const roundedTotal = Math.round(total / 10) * 10;
      // Форматируем число с одним десятичным знаком и добавляем суффикс "ah"
      return `${roundedTotal.toFixed(1)}ah`;
    },
    setOptions() {
      const series = []
      if (!this.data) {
        return false
      }

      this.data.activityTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.map((at) => {
        let total = null
        total = this.data.map.reduce((acc, obj) => {
          let value = Number(obj[at.name])
          return isNaN(value) ? acc : acc + value
        }, 0)
        return {
          ...at,
          active: this.$store.getters.getEfcChartTagsVisible(at.name),
          total
        }
      }) : []
      const activeTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.filter((at) => at.active) : []

      if (this.data && activeTags.length > 0) {
        for (const activityTag of activeTags) {
          series.push({
            type: 'bar',
            xKey: 'month',
            stacked: true,
            yKey: activityTag.name,
            yName: activityTag.label,
            fill: activityTag.color,
            stroke: activityTag.color,
            tooltip: {
              renderer: function ({ datum, xKey, yKey }) {
                let sum = 0;
                for (const key in datum) {
                  if (key !== 'month' && key !== 'total_revenue' && typeof datum[key] === 'number') {
                    if (activeTags.length > 0 && activeTags.map((at) => at.name).includes(key)) {
                      sum += datum[key];
                    }
                  }
                }
                const sumLabel = SimpleHelper.priceFormatter(sum) + 'ah';
                return {
                  content: `<strong>Total:</strong> ${sumLabel.replaceAll('$', '')}<br><strong>${yKey.replaceAll('_', ' ').charAt(0).toUpperCase() + yKey.replaceAll('_', ' ').slice(1)}</strong>: ${datum[yKey] + 'ah'}`,
                  title: SimpleHelper.monthYearStringToLabels(datum[xKey])
                }
              }
            }
          })
        }
        series.push({
          type: 'line',
          xKey: 'month',
          yKey: 'total_revenue',
          stroke: '#5D9B5C',
          strokeWidth: 4,
          marker: {
            enabled: true,
            stroke: '#5D9B5C', 
            size: 10,
            fill: "#5D9B5C"
          },
          tooltip: {
            renderer: function ({ datum, xKey, yKey }) {
              return {
                content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${datum[yKey] + 'ah'}`,
                title: yKey.replaceAll('_', ' ')
              }
            }
          }
        })
        this.options = {
          height: 600,
          data: this.data.map,
          series: series,
          legend: {
            enabled: false
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left'
            }
          ]
        }

      } else {
        this.options = JSON.parse(JSON.stringify({
          height: 600,
          data: [],
          series: []
        }))
      }
    },
    toggleActive(index) {
      this.$store.commit('setEfcChartTagsVisible', { key: this.data.activityTags[index].name, value: !this.data.activityTags[index].active });
      this.$set(this.data.activityTags, index, {
        ...this.data.activityTags[index],
        active: !this.data.activityTags[index].active
      })
      this.setOptions()
    },
    toggleAll(active) {
      this.data.activityTags = this.data.activityTags.map((at) => {
        this.$store.commit('setEfcChartTagsVisible', { key: at.name, value: active });
        return {
          ...at,
          active
        }
      })
      this.setOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
.b-skeleton {
  border-radius: 0;
}
.charts {
  border: 1px solid #e6e6e6;
}
.buttons-group {
  display: flex;
  margin: 0px 25px 10px;
  gap: 10px;
}
.custom-legend {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 20px 20px 20px;
}
.legend-item {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 5px;
}
.legend-item .label {
  font-size: 0.8rem;
}
.legend-item:hover {
  background-color: #f0f0f0;
}
.legend-item.active {
  opacity: 1;
}
.legend-item.inactive {
  opacity: 0.2;
}
.legend-item .color {
  display: block;
  height: 20px;
  width: 20px;
}
.hidden-checkbox {
  display: none;
}
</style>
